import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import "react-multi-carousel/lib/styles.css";
import Header from '../components/Header/Header-2';
import Layout from '../components/Layout/Layout';
import SEO from "../components/seo";
import Lottie from 'react-lottie';
import animationData16 from '../images/animations/anim16.json';

const getdata = graphql`
{
  wpgraphql {
    page(id: "cG9zdDoxNjg1OQ==") {
      uri
      title
      slug
      content
      seo {
        canonical
        metaDesc
        metaKeywords
        focuskw
        title
        twitterTitle
        twitterDescription
        opengraphDescription
        opengraphPublishedTime
        opengraphModifiedTime
        opengraphTitle
        opengraphType
        opengraphImage {
          sourceUrl
        }
      }
    }
  }
}
  `
const FreeTools = () => {
    const data = useStaticQuery(getdata);
    const common = data.wpgraphql.page;
    const desc = data.wpgraphql.page.seo.metaDesc
    const seo = data.wpgraphql.page.seo
    const uri = data.wpgraphql.page.uri
    //
    const getLottieOptions = (data) => {
        return {
            loop: true,
            autoplay: true,
            animationData: data,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice"
            }
        };
    }
    return (
        <>
            <Layout>
                <SEO title="Power Patent" description={desc} canonical={'/free-tools'} seo={seo} />
                <Header home={false}  menu='tools'/>
                <main>
                    <section class="py-5 bg-light-grey">
                        <div class="container">
                            <div class="row align-items-center">
                                <div class="col-md-6 pe-lg-5 pe-0">
                                    <h1 class="hiw-title mb-4 text-primary pe-lg-5 pe-0">Free Tools</h1>
                                    <p class="hiw-desc mb-5 pe-lg-5 pe-0">Patent Analyzer<sup>TM</sup>, a free tool developed by
                                        patent attorneys for patent attorneys, aids in checking patents for internal consistency
                                        and tailoring patent applications concisely. By streamlining patent analysis and serving
                                        as a quality control check during drafting, this software empowers legal professionals
                                        to work more efficiently and mitigate legal risks in patent prosecution and litigation.
                                    </p>
                                    <p class="m-0"><a href="/patent-analyzer" class="btn btn-lg btn-warning px-5">Try It</a></p>
                                </div>
                                <div class="col-md-6 px-5">
                                    <div id="animation16">
                                    <Lottie options={getLottieOptions(animationData16)}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="py-5">
                        <div class="container py-5">
                            <h3 class="hiw-subtitle py-2 text-center">Benefits of Using Patent Analyzer</h3>
                            <div class="row justify-content-center">
                                <div class="col-md-4 p-4">
                                    <div class="box-shadow h-100 why-box tool-box bg-white">
                                        <div class="d-flex flex-column gap-4 mb-3">
                                            <img src="./assets/img/icons/goal.png" alt="Icon" class="why-icon"/>
                                            <h3 class="why-title"> Consistency and accuracy check</h3>
                                        </div>
                                        <p class="why-desc">PatentAnalyzer can be used to verify the consistency and accuracy of
                                            your patent draft in terms of claims, literature, and terminology.</p>
                                    </div>
                                </div>
                                <div class="col-md-4 p-4">
                                    <div class="box-shadow h-100 why-box tool-box bg-white">
                                        <div class="d-flex flex-column gap-4 mb-3">
                                            <img src="./assets/img/icons/dashboard.png" alt="Icon" class="why-icon"/>
                                            <h3 class="why-title"> Analysis of publications or patents</h3>
                                        </div>
                                        <p class="why-desc">Analyze public patent text documents to determine if your
                                            application is comparable to other patent applications.</p>
                                    </div>
                                </div>
                                <div class="col-md-4 p-4">
                                    <div class="box-shadow h-100 why-box tool-box bg-white">
                                        <div class="d-flex flex-column gap-4 mb-3">
                                            <img src="./assets/img/icons/data-source.png" alt="Icon" class="why-icon"/>
                                            <h3 class="why-title">Citation source search</h3>
                                        </div>
                                        <p class="why-desc"> You can search directly for patents and publications in your space
                                            and then analyze one or more of the patents/publications.</p>
                                    </div>
                                </div>
                                <div class="col-md-4 p-4">
                                    <div class="box-shadow h-100 why-box tool-box bg-white">
                                        <div class="d-flex flex-column gap-4 mb-3">
                                            <img src="./assets/img/icons/comparison.png" alt="Icon" class="why-icon"/>
                                            <h3 class="why-title">Research and comparison of claim components</h3>
                                        </div>
                                        <p class="why-desc"> Comparing claim components, terminology, and wording in multiple
                                            documents in patents or your document.</p>
                                    </div>
                                </div>
                                <div class="col-md-4 p-4">
                                    <div class="box-shadow h-100 why-box tool-box bg-white">
                                        <div class="d-flex flex-column gap-4 mb-3">
                                            <img src="./assets/img/icons/dictionary.png" alt="Icon" class="why-icon"/>
                                            <h3 class="why-title">Wording and Terminology Check</h3>
                                        </div>
                                        <p class="why-desc"> A list of cases should be compiled that includes references to and
                                            interpretations of key terms and formulas within your patent document.
                                            PatentAnalyzer can find and report terminology using the user's dictionary.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="py-5">
                        <div class="py-5 bg-sky">
                            <div class="container">
                                <div class="row gap-md-0 gap-4 align-items-center flex-md-row flex-column">
                                    <div class="col-md-6 pe-lg-5 pe-md-4 p-3">
                                        <img src="./assets/img/woman-reading-privacy-policy-doc.webp" alt="Image"
                                            class="h-100 w-100 tool-img"/>
                                    </div>
                                    <div class="col-md-6 ps-lg-5 ps-md-4 p-3">
                                        <h3 class="tool-title pb-3">How <span class="text-primary">Patent Analyzer</span> works
                                        </h3>
                                        <p class="anim-desc">We provide insight and analysis to assist our users in making
                                            informed decisions. Our machine learning (ML) stack allows us to build precise
                                            custom entity recognition models and extract insights from legal documents such as
                                            patent documents. We are constantly looking for new ways to apply natural language
                                            processing to improve our patent analytics.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="pb-5">
                        <div class="container">
                            <div class="px-md-5 mt-3 text-center anim-desc">
                                <p>Please note that while Patent Analyzer provides valuable analysis, it is not a substitute for
                                    legal advice. Consultation with a qualified patent attorney is recommended for specific
                                    legal matters related to patents and intellectual property. </p>
                                <p class="py-3"> <b>Disclaimer:</b> The information provided by Patent Analyzer is for informational purposes only
                                    and does not constitute legal advice. </p>
                                <div class="pt-4">
                                    {/* <a href="#" class="btn btn-lg btn-warning px-5">View Report</a> */}
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </Layout>
        </>
    )
}
export default FreeTools
